<template>
    <a-drawer
        :zIndex="1000"
        :title="title"
        :width="'650px'"
        @close="onClose"
        :maskClosable="false"
        :visible="dialog.show"
        :drawerStyle="{ height: 'calc(100% - 50px)', overflow: 'auto' }"
    >
        <bh-loading :show="loading" />
		<a-modal :visible="previewModal.visible" @cancel="closeModal" :footer="null" centered>
            <div class="mt-3">
                <img style="height:100%; width:100%" :src="previewModal.item" />
            </div>
        </a-modal>
        <a-form-model :model="lot" ref="lot" class="h-full dF fC jSB">
            <div class="f1">
                <a-row :gutter="16">
                    <h6 class="ml-3">Lot Details</h6>
                    <hr />

					<a-col :span="24" v-if="dialog.type === 'add'">
                        <a-form-model-item label="Map lot polygon to existing lot">
                            <a-select v-model="selectedLot">
                                <a-select-option
                                    v-for="lot in lots"
                                    :key="lot.value"
                                    :value="lot.value"
                                    >{{ lot.name }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-model-item
                            required
                            :rules="req('Please enter Lot #')"
                            label="Lot #"
                            prop="name"
                        >
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.name"
                                placeholder="Example value: 1"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-model-item label="Block #" prop="block">
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.block"
                                placeholder="Example value: 1"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="12">
                        <a-form-model-item label="Tentative/Firm Close Date" prop="closingDate">
							<template v-if="lot.dynamicClosingDate">
								<a-tooltip
									overlayClassName="change-tooltip-color"
									style="
										position: absolute;
										top: -30px;
										left: 158px;
									"
								>
									<template slot="title">
										Close date will be calculated based on the number of days, months, or years from the offer date.
									</template>
									<a-icon
										type="question-circle"
										style="
											line-height: 22.5px;
											margin-top: 5px;
											color: var(--orange);
										"
									/>
								</a-tooltip>
								<a-row :gutter="16">
									<a-col :span="8">
										<a-input-number
											v-model="lot.dynamicClosingDateValue"
											style="width: 100%"
											:min="0"
											:max="366"
										></a-input-number>
									</a-col>
									<a-col :span="16">
										<a-select
											v-model="lot.dynamicClosingDateUnit"
											style="width: 100%"
										>
											<a-select-option value="days">Days</a-select-option>
											<a-select-option value="months">Months</a-select-option>
											<a-select-option value="years">Years</a-select-option>
										</a-select>
									</a-col>
								</a-row>
							</template>
                            <a-date-picker v-else style="width:100%" v-model="lot.closingDate" :disabled-date="disabledDate" placeholder="YYYY-MM-DD"></a-date-picker>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="12">
                        <a-form-model-item label="Dynamic Closing Date" prop="dynamicClosingDate">
                            <a-switch v-model="lot.dynamicClosingDate"></a-switch>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Publishing" prop="block">
                            <a-tooltip
								overlayClassName="change-tooltip-color"
								:title="
                                    $p < 40
                                        ? 'You do not have permission to modify the publishing status'
                                        : ''
                                "
                            >
                                <a-select
                                    :disabled="
                                        $p < 40 ||
                                        (hasChildren &&
                                            parentLot.inherit != 'none')
                                    "
                                    v-model="lot.publishStatus"
                                    style="width: 100%"
                                >
                                    <a-select-option value="draft"
                                        >Draft</a-select-option
                                    >
                                    <a-select-option value="published"
                                        >Published</a-select-option
                                    >
                                </a-select>
                            </a-tooltip>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="12">
                        <a-form-model-item label="Tags" prop="tags">
                            <a-select
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                mode="tags"
                                v-model="lot.tags"
                                style="width: 100%"
                                placeholder="Phase 1 / Hide / Walkout"
                            >
                                <a-select-option
                                    v-for="tag in tags"
                                    :value="tag.name"
                                    :key="tag.name"
                                >
                                    {{ tag.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
					<h6 class="ml-3">Primary Address</h6>
                    <hr />

                    <a-col :span="8">
                        <a-form-model-item
                            label="Street Number"
                            prop="streetNumber"
                        >
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                :min="0"
                                type="number"
                                v-model="lot.streetNumber"
                                placeholder="Street Number"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="Street Name" prop="street">
                            <a-auto-complete
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.street"
                                :data-source="dataSource"
                                style="width: 100%"
                                placeholder="Street Name"
                                @search="onSearch"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item
                            :label="
                                country === 'CA' ? 'Postal Code' : 'Zip Code'
                            "
                            prop="postal"
                        >
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.postal"
                                :placeholder="
                                    country === 'CA'
                                        ? 'Postal Code'
                                        : 'Zip Code'
                                "
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16">
                    <a-col :span="8">
                        <a-form-model-item label="City" prop="city">
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.city"
                                placeholder="City"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item
                            :label="country === 'CA' ? 'Province' : 'State'"
                            prop="region"
                        >
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.region"
                                :placeholder="
                                    country === 'CA' ? 'Province' : 'State'
                                "
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="8">
                        <a-form-model-item
                            label="Short Legal Description"
                            prop="legalDescription"
                        >
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.legalDescription"
                                placeholder="Short Legal Description"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16">
					<h6 class="ml-3">Secondary Address (If applicable)</h6>
                    <hr />

                    <a-col :span="8">
                        <a-form-model-item
                            label="Street Number"
                            prop="streetNumber"
                        >
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                :min="0"
                                type="number"
                                v-model="lot.streetNumber2"
                                placeholder="Street Number"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item label="Street Name" prop="street">
                            <a-auto-complete
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.street2"
                                :data-source="dataSource"
                                style="width: 100%"
                                placeholder="Street Name"
                                @search="onSearch"
                            />
                        </a-form-model-item>
                    </a-col>
					<a-col :span="8">
                        <a-form-model-item
                            :label="
                                country === 'CA' ? 'Postal Code' : 'Zip Code'
                            "
                            prop="postal"
                        >
                            <a-input
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.postal2"
                                :placeholder="
                                    country === 'CA'
                                        ? 'Postal Code'
                                        : 'Zip Code'
                                "
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item
                            label="Choose all Home Models that fit this lot"
                            prop="units"
                        >
                            <a-tree-select
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                :treeData="selectUnits"
                                :value="lot.units"
                                @change="onUnitSelect"
                                treeCheckable
                                :showCheckedStrategy="SHOW_CHILD"
                                searchPlaceholder="Please select"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16" v-if="showForm || true">
                    <a-col :span="12">
                        <a-form-model-item
                            label="Model Type"
                            prop="modelType"
                            ref="modelType"
                        >
                            <a-tooltip
                                v-if="differentTypes"
                                overlayClassName="change-tooltip-color"
                                style="
                                    position: absolute;
                                    top: -33px;
                                    left: 100px;
                                "
                            >
                                <template slot="title">
                                    There has been a change in lot type. Please
                                    ensure all your models are the same type.
                                </template>
                                <a-icon
                                    type="warning"
                                    style="color: var(--danger)"
                                />
                            </a-tooltip>
                            <a-tooltip
                                overlayClassName="change-tooltip-color"
                                style="
                                    position: absolute;
                                    top: -40px;
                                    left: 78px;
                                "
                            >
                                <template slot="title">
                                    Identify the model type (i.e. Singles, Semi
                                    Detached, Towns).
                                </template>
                                <a-icon
                                    type="question-circle"
                                    style="
                                        line-height: 22.5px;
                                        margin-top: 5px;
                                        color: var(--orange);
                                    "
                                />
                            </a-tooltip>
                            <a-input
                                :disabled="Boolean(lot && lot.units && lot.units.length)"
                                v-model="lot.modelType"
                                placeholder="Single, Semi, Town, etc..."
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item
                            label="Lot Frontage"
                            prop="size"
                            ref="size"
                        >
                            <!-- <a-tooltip
                                v-if="differentFrontages"
                                overlayClassName="change-tooltip-color"
                                style="
                                    position: absolute;
                                    top: -33px;
                                    left: 83px;
                                "
                            >
                                <template slot="title">
                                    There has been a change in lot frontage.
                                    Please ensure all your models are the same
                                    size.
                                </template>
                                <a-icon
                                    type="warning"
                                    style="color: var(--danger)"
                                />
                            </a-tooltip> -->
                            <a-input
                                type="number"
                                :disabled="Boolean(lot && lot.units && lot.units.length)"
                                v-model="lot.size"
                                placeholder="25, 35, 40"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>

				<a-row :gutter="16">
					<a-col :span="12">
                        <a-form-model-item
                            label="Deactivate architectural rules"
                        >
                            <a-switch v-model="lot.deactivateArchitecturalRules" />
                        </a-form-model-item>
                    </a-col>
					<a-col :span="12">
                        <a-form-model-item
                            label="Is this a Standing Inventory Lot?"
                        >
                            <a-switch v-model="standingInventory" />
                        </a-form-model-item>
                    </a-col>
				</a-row>

				<a-row :gutter="16" v-if="standingInventory" class="mb-3">
					<GalleryImage :galleryImages="(lot && lot.media && lot.media.mediaImage)" @previewGallery="previewGallery" @galleryImages="galleryImg" :title="'Gallery Images'" />
				</a-row>



                <a-row :gutter="16">
                    <a-col v-if="hasChildren" :span="24">
                        <a-form-model :model="parentLot" ref="parentLot">
                            <a-form-model-item
                                label="Inherit data from"
                                prop="inherit"
                                required
                                :rules="req('Please select one')"
                            >
                                <a-select v-model="parentLot.inherit">
                                    <a-select-option value="none"
                                        >Don't Inherit</a-select-option
                                    >
                                    <a-select-opt-group
                                        v-for="children in childrenLots"
                                        :key="children.id"
                                    >
                                        <span slot="label">{{
                                            children.name
                                        }}</span>
                                        <a-select-option
                                            v-for="lot in children.lots"
                                            :key="lot.id"
                                            :value="lot.id"
                                            >{{ lot.name }}</a-select-option
                                        >
                                    </a-select-opt-group>
                                </a-select>
                            </a-form-model-item>
                        </a-form-model>
                    </a-col>
                    <a-col :span="24">
                        <div
                            class="mb-3"
                            style="color: var(--danger)"
                            v-if="
                                dialog.type != 'add' &&
								parentLot.inherit == 'none' &&
                                lot.status != 'available' &&
                                lot.status != 'inventory' &&
                                lot.status != 'notreleased'
                                    ? true
                                    : false
                            "
                        >
                            This lot cannot be updated because it is sold or
                            pending
                        </div>
                        <a-row :gutter="16">
                            <h6 class="ml-3">Lot Relations</h6>
                            <hr />
                            <a-col :span="8">
                                <a-form-model-item
                                    label="Lot Front"
                                    prop="front"
                                >
                                    <div
                                        style="
                                            line-height: 1;
                                            user-select: none;
                                            pointer-events: none;
                                            opacity: 0;
                                        "
                                    >
                                        Attached ? <a-checkbox />
                                    </div>
                                    <a-select
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                        "
                                        v-model="lot.front"
                                        style="width: 100%"
                                    >
                                        <a-select-option
                                            v-for="lot in lots"
                                            :value="lot.value"
                                            :key="lot.id"
                                        >
                                            {{ lot.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="Lot Left" prop="left">
                                    <div
                                        style="
                                            line-height: 1;
                                            user-select: none;
                                        "
                                    >
                                        Attached ?
                                        <a-checkbox
                                            :disabled="
                                                hasChildren &&
                                                parentLot.inherit != 'none'
                                            "
                                            v-model="lot.attachedLeft"
                                        />
                                    </div>
                                    <a-select
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                        "
                                        v-model="lot.left"
                                        style="width: 100%"
                                    >
                                        <a-select-option
                                            v-for="lot in lots"
                                            :value="lot.value"
                                            :key="lot.id"
                                        >
                                            {{ lot.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item
                                    label="Lot Right"
                                    prop="right"
                                >
                                    <div
                                        style="
                                            line-height: 1;
                                            user-select: none;
                                        "
                                    >
                                        Attached ?
                                        <a-checkbox
                                            :disabled="
                                                hasChildren &&
                                                parentLot.inherit != 'none'
                                            "
                                            v-model="lot.attachedRight"
                                        />
                                    </div>
                                    <a-select
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                        "
                                        v-model="lot.right"
                                        style="width: 100%"
                                    >
                                        <a-select-option
                                            v-for="lot in lots"
                                            :value="lot.value"
                                            :key="lot.id"
                                        >
                                            {{ lot.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <h6 class="ml-3">Lot Gradings</h6>
                    <hr />
                    <a-col :span="12">
                        <a-form-model-item
                            label="Garage Side"
                            prop="garageSide"
                        >
                            <a-select
                                :disabled="
                                    hasChildren && parentLot.inherit != 'none'
                                        ? true
                                        : false
                                "
                                v-model="lot.garageSide"
                            >
                                <a-select-option
                                    v-for="side in garageSide"
                                    :key="side.value"
                                    :value="side.value"
                                    >{{ side.label }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Risers at Garage">
                            <a-row>
                                <a-col :span="11">
                                    <a-input
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                                ? true
                                                : false
                                        "
                                        :min="0"
                                        type="number"
                                        v-model="lot.garageRiserMin"
                                        placeholder="Min #"
                                    ></a-input>
                                </a-col>
                                <a-col :span="2" class="text-center">
                                    to
                                </a-col>
                                <a-col :span="11">
                                    <a-input
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                                ? true
                                                : false
                                        "
                                        :min="0"
                                        type="number"
                                        v-model="lot.garageRiserMax"
                                        placeholder="Max #"
                                    ></a-input>
                                </a-col>
                            </a-row>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Risers at Front">
                            <a-row>
                                <a-col :span="11">
                                    <a-input
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                                ? true
                                                : false
                                        "
                                        :min="0"
                                        type="number"
                                        v-model="lot.frontRiserMin"
                                        placeholder="Min #"
                                    ></a-input>
                                </a-col>
                                <a-col :span="2" class="text-center">
                                    to
                                </a-col>
                                <a-col :span="11">
                                    <a-input
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                                ? true
                                                : false
                                        "
                                        :min="0"
                                        type="number"
                                        v-model="lot.frontRiserMax"
                                        placeholder="Max #"
                                    ></a-input>
                                </a-col>
                            </a-row>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Risers at Rear">
                            <a-row>
                                <a-col :span="11">
                                    <a-input
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                                ? true
                                                : false
                                        "
                                        :min="0"
                                        type="number"
                                        v-model="lot.rearRiserMin"
                                        placeholder="Min #"
                                    ></a-input>
                                </a-col>
                                <a-col :span="2" class="text-center">
                                    to
                                </a-col>
                                <a-col :span="11">
                                    <a-input
                                        :disabled="
                                            hasChildren &&
                                            parentLot.inherit != 'none'
                                                ? true
                                                : false
                                        "
                                        :min="0"
                                        type="number"
                                        v-model="lot.rearRiserMax"
                                        placeholder="Max #"
                                    ></a-input>
                                </a-col>
                            </a-row>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item>
                            <a-checkbox
                                v-model="lot.easement"
                                style="color: #40454c"
                                >Easement</a-checkbox
                            >
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16" v-if="!refresh">
                    <template v-if="Object.keys(lotGradings).length != 0">
                        <a-col
                            :span="8"
                            v-for="grading in Object.keys(lotGradings)"
                            :key="grading"
                        >
                            <a-form-model-item>
                                <div class="dF aC">
                                    <a-checkbox
                                        style="color: #40454c"
                                        class="mr-2"
                                        :defaultChecked="
                                            checkDefault(grading, 'lotGradings')
                                        "
                                        @change="
                                            (e) =>
                                                updateCheckbox(
                                                    e,
                                                    grading,
                                                    'lotGradings'
                                                )
                                        "
                                        >{{
                                            lotGradings[grading].name
                                        }}</a-checkbox
                                    >
                                </div>
                            </a-form-model-item>
                        </a-col>
                    </template>
                    <template
                        v-if="
                            hasChildren && parentLot.inherit != '' && lot.other && lot.other.gradings
                        "
                    >
                        <a-col
                            :span="8"
                            v-for="grade in Object.keys(lot.other.gradings)"
                            :key="grade"
                        >
                            <a-form-model-item>
                                <div class="dF aC">
                                    <a-checkbox
                                        style="color: #40454c"
                                        class="mr-2"
                                        :defaultChecked="
                                            checkDefault(
                                                grade,
                                                'lot.other.gradings'
                                            )
                                        "
                                        @change="
                                            (e) =>
                                                updateCheckbox(
                                                    e,
                                                    grade,
                                                    'lot.other.gradings'
                                                )
                                        "
                                        >{{
                                            lot.other.gradings[grade].name
                                        }}</a-checkbox
                                    >
                                </div>
                            </a-form-model-item>
                        </a-col>
                    </template>
                    <a-col
                        v-if="!hasChildren || parentLot.inherit == 'none'"
                        :span="24"
                    >
                        <a-button @click="addNewGrading" type="primary"
                            ><a-icon type="plus" />ADD LOT GRADINGS</a-button
                        >
                    </a-col>
                </a-row>

                <a-row :gutter="16" style="margin-top: 28px">
                    <a-col :span="24">
                        <a-row :gutter="16" v-if="!refresh">
                            <h6 class="ml-3">Lot Conditions / Premiums</h6>
                            <hr />
                            <template
                                v-if="Object.keys(lotPremiums).length != 0"
                            >
                                <template
                                    v-if="
                                        parentLot.inherit == '' ||
                                        parentLot.inherit == 'none'
                                    "
                                >
                                    <a-col
                                        :span="8"
                                        v-for="premium in Object.keys(
                                            lotPremiums
                                        )"
                                        :key="premium"
                                    >
                                        <a-form-model-item
                                            :label="lotPremiums[premium].name"
                                        >
                                            <div class="dF aC">
                                                <a-checkbox
                                                    class="mr-2"
                                                    v-model="lotPremiums[premium].active"
                                                ></a-checkbox>
                                                <a-input-number
                                                    v-model="lotPremiums[premium].price"
                                                    :min="0"
                                                    :formatter="
                                                        (value) =>
                                                            `$ ${value}`.replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ','
                                                            )
                                                    "
                                                    :parser="
                                                        (value) =>
                                                            value.replace(
                                                                /\$\s?|(,*)/g,
                                                                ''
                                                            )
                                                    "
                                                    :default-value="
                                                        lotPremiums[premium]
                                                            .price
                                                    "
                                                />
                                            </div>
                                        </a-form-model-item>
                                    </a-col>
                                </template>
                                <template
                                    v-else-if="
                                        hasChildren &&
                                        parentLot.inherit != '' &&
                                        lot.other
                                    "
                                >
                                    <a-col
                                        :span="8"
                                        v-for="(
                                            premium, premiumI
                                        ) in Object.keys(lot.other.premiums)"
                                        :key="premium + premiumI"
                                    >
                                        <a-form-model-item
                                            :label="
                                                lot.other.premiums[premium].name
                                            "
                                        >
                                            <div class="dF aC">
                                                <a-checkbox
                                                    class="mr-2"
                                                    v-model="lot.other.premiums[premium].active"
                                                ></a-checkbox
                                                ><a-input
                                                    :disabled="true"
                                                    v-model="lot.other.premiums[premium].price"
                                                    type="number"
                                                    :formatter="
                                                        (value) =>
                                                            `$ ${value}`.replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ','
                                                            )
                                                    "
                                                    :parser="
                                                        (value) =>
                                                            value.replace(
                                                                /\$\s?|(,*)/g,
                                                                ''
                                                            )
                                                    "
                                                    :min="0"
                                                    :default-value="
                                                        lot.other.premiums[
                                                            premium
                                                        ].price
                                                    "
                                                />
                                            </div>
                                        </a-form-model-item>
                                    </a-col>
                                </template>
                            </template>
                            <a-col
                                v-if="
                                    !hasChildren || parentLot.inherit == 'none'
                                "
                                :span="24"
                            >
                                <a-button @click="addNewPremium" type="primary"
                                    ><a-icon type="plus" />ADD LOT CONDITIONS /
                                    PREMIUMS</a-button
                                >
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>

				<a-row :gutter="16" style="margin-top: 28px">
                    <a-col :span="24">
                        <a-row :gutter="16" v-if="!refresh2">
                            <h6 class="ml-3">Lot Custom Fields</h6>
                            <hr />
							<template v-if="lot.other && lot.other.customFields">
								<a-col :span="24" v-for="field in customFields" :key="field.id">
									<a-form-model-item :label="field.name" :prop="`other.customFields[${field.id}]`" :required="!!field.options.required" :rules="req(`Please enter ${field.name}`, !!field.options.required)">
										<a-input v-if="field.type == 'text'" v-model="lot.other.customFields[field.id]" />
										<a-input v-else-if="field.type == 'number'" type="number" v-model="lot.other.customFields[field.id]" />
										<template v-else-if="field.type == 'checkbox'">
											<a-checkbox v-for="(ans, ansI) in field.options.multipleChoices" :value="ansI" :key="ansI" @change="(e) => changeCheck(field.id, e)" :checked="ansI == lot.other.customFields[field.id]">{{ans}}</a-checkbox>
										</template>
										<template v-else-if="field.type == 'multiplechoice'">
											<a-select v-model="lot.other.customFields[field.id]" mode="multiple">
												<a-select-option v-for="(ans, ansI) in field.options.multipleChoices" :key="ansI" :value="ansI">{{ans}}</a-select-option>
											</a-select>
										</template>
										<template v-else-if="field.type == 'date'">
											<a-date-picker style="width:100%" v-model="lot.other.customFields[field.id]"/>
										</template>
									</a-form-model-item>
								</a-col>
							</template>
							<a-col
                                v-if="
                                    !hasChildren || parentLot.inherit == 'none'
                                "
                                :span="24"
                            >
                                <a-button @click="$store.commit('UPDATE_CUSTOM_FIELD_MODAL', true)" type="primary"
                                    ><a-icon type="plus" />ADD CUSTOM FIELD</a-button
                                >
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </div>

            <div
                :style="{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }"
            >
                <div class="dF jSB">
                    <div>
                        <a-button
                            @click="deleteButton"
                            :disabled="deleting"
                            v-if="dialog.type === 'edit'"
                            type="danger"
                            ghost
                            icon="delete"
                            >DELETE LOT</a-button
                        >
                    </div>
                    <div>
                        <a-button
                            class="cancel-button"
                            :style="{ marginRight: '8px' }"
                            style="width: 120px"
                            @click="onClose"
                        >
                            CANCEL
                        </a-button>
                        <a-button
                            style="width: 120px"
                            type="primary"
                            @click="submitButton"
                            >{{
                                this.selectedLot !== "none" ? "MAP" : dialog.type === "add" ? "CREATE" : "UPDATE"
                            }}</a-button
                        >
                    </div>
                </div>
            </div>
        </a-form-model>
    </a-drawer>
</template>

<script>
import { formatNumber } from "bh-mod";
import bhLoading from "bh-mod/components/common/Loading";
import { TreeSelect } from "ant-design-vue";
import moment from "moment"
import GalleryImage from '@/components/siteplan/GalleryImage'

export default {
    components: { bhLoading, GalleryImage },

    data() {
        return {
            differentTypes: false,
            differentFrontage: false,
            standingInventory: false,
			differentFrontages: false,
            showForm: true,
            savedEditLot: {},
            parentLot: {
                inherit: "",
            },
            dataSource: [],
            defaultLot: {
                easement: false,
                modelType: "",
                size: "",
                name: "",
                image: "",
                publishStatus: "draft",
				closingDate: null,
				dynamicClosingDate: false,
				dynamicClosingDateUnit: "days",
				dynamicClosingDateValue: 0,
                left: "none",
                front: "none",
                right: "none",
                block: "",
                siteplan: this.$store.state.siteplan.appData.id,
                description: "",
                type: "lot",
                premiums: [], //
                shapeId: "",
                shape: {},
                units: [],
                tags: [],
                unitType: "",
                street: "",
				street2: "",
				streetNumber: "",
				streetNumber2: "",
				postal: "",
				postal2: "",
				city:"",
				region:"",
				legalDescription:"",
                status: "available",
                garageSide: "none",
                frontRiserMin: 0,
                frontRiserMax: 0,
                garageRiserMin: 0,
                garageRiserMax: 0,
                wob: true,
                rearRiserMin: 0,
                rearRiserMax: 0,
                condition: "none",
                notes: "",
                uniqueGrades: [],
                other: { customFields : {}},
                attachedRight: false,
                attachedLeft: false,
				deactivateArchitecturalRules: false
            },
            deleting: false,
            deletePopup: false,
            activeKey: "1",
            loading: false,
            lotUnitType: "",
            SHOW_CHILD: TreeSelect.SHOW_CHILD,
            premiums: ["Walkout", "Look Out", "Pie Shape"],
            uniqueGrades: [],
            newPremium: {
                name: "",
                value: "",
                active: false,
            },
            newGrading: {
                name: "",
                value: "",
                active: false,
            },
            wob: "",
            status: [
                { label: "Available", value: "available" },
                // {label:'Pending',value:'hold'},
                // {label:'Sold',value:'sold'},
                { label: "Inventory", value: "inventory" },
            ],
            condition: [
                { label: "Rear Upgrade", value: "rearUpgrade" },
                { label: "Side and Rear Upgrapde", value: "sideRearUpgrade" },
                { label: "Corner Upgrade", value: "cornerUpgrade" },
                { label: "Walk-out Basement", value: "wob" },
                { label: "Easement", value: "easement" },
                { label: "No Condition", value: "none" },
                { label: "Other", value: "other" },
            ],
            garageSide: [
                { label: "None", value: "none" },
                { label: "Right", value: "right" },
                { label: "Left", value: "left" },
                { label: "Center", value: "center" },
                { label: "Rear", value: "rear" },
                { label: "Other", value: "other" },
            ],
            lot: {
                easement: false,
                modelType: "",
                size: "",
                publishStatus: "draft",
				closingDate: null,
				dynamicClosingDate: false,
				dynamicClosingDateUnit: "days",
				dynamicClosingDateValue: 0,
                name: "",
                image: "",
                left: "",
                block: "",
                front: null,
                right: null,
                siteplan: this.$store.state.siteplan.appData.id,
                description: "",
                type: "lot",
                premiums: [], //
                shapeId: "",
                shape: {},
                units: [],
                tags: [],
                unitType: "",
                street: "",
				street2: "",
				streetNumber: "",
				streetNumber2: "",
				postal: "",
				postal2: "",
				city:"",
				region:"",
				legalDescription:"",
                status: "available",
                garageSide: "none",
                frontRiserMin: 0,
                frontRiserMax: 0,
                garageRiserMin: 0,
                garageRiserMax: 0,
                wob: true,
                rearRiserMin: 0,
                rearRiserMax: 0,
                condition: "none",
                notes: "",
                uniqueGrades: [],
                other: { customFields: {}},
				deactivateArchitecturalRules: false,
				media: {
					mediaImage: [],
				},

            },
            lotGradings: {},
            lotPremiums: {},
            lotConditions: {},
            refresh: false,
			refresh2: false,
			selectedLot: 'none',
			previewModal: {
				visible: false,
				item: ''
			},
        };
    },
    watch: {
        storeGradings: {
            handler(val) {
                if (this.lotGradings && Object.keys(this.lotGradings).length) {
                    if (val.length > Object.keys(this.lotGradings).length) {
                        let gradings = Object.keys(this.lotGradings);
                        let map = {};
                        JSON.parse(JSON.stringify(val)).forEach((x) => {
                            if (!gradings.includes(x.id)) {
                                map[x.id] = {
                                    name: x.name,
                                    price: x.price,
                                    active: x.active,
                                };
                            } else {
                                map[x.id] = this.lotGradings[x.id];
                            }
                        });
                        this.lotGradings = map;
                        if (
                            this.savedEditLot.other &&
                            this.savedEditLot.other.gradings
                        )
                            this.savedEditLot.other.gradings = this.lotGradings;
                        if (
                            this.savedEditLot.other &&
                            this.savedEditLot.other.premiums
                        )
                            this.savedEditLot.other.premiums = this.lotPremiums;
                        this.refresh = true;
                        setTimeout(() => {
                            this.refresh = false;
                        }, 50);
                    }
                } else if (
                    val.length == 1 &&
                    Object.keys(this.lotGradings).length == 0
                ) {
                    let grading = JSON.parse(JSON.stringify(val));
                    this.lotGradings[grading[0].id] = grading[0];
                    this.refresh = true;
                    setTimeout(() => {
                        this.refresh = false;
                    }, 50);
                }
            },
        },
        storePremiums: {
            handler(val) {
                if (this.lotPremiums && Object.keys(this.lotPremiums).length) {
                    if (val.length > Object.keys(this.lotPremiums).length) {
                        let premiums = Object.keys(this.lotPremiums);

                        let map = {};
                        JSON.parse(JSON.stringify(val)).forEach((x) => {
                            if (!premiums.includes(x.id)) {
                                map[x.id] = {
                                    name: x.name,
                                    price: x.price,
                                    active: x.active,
                                };
                            } else {
                                map[x.id] = this.lotPremiums[x.id];
                            }
                        });
                        this.lotPremiums = map;

                        if (
                            this.savedEditLot.other &&
                            this.savedEditLot.other.premiums
                        )
                            this.savedEditLot.other.premiums = map;
                        if (
                            this.savedEditLot.other &&
                            this.savedEditLot.other.gradings
                        )
                            this.savedEditLot.other.gradings = this.lotGradings;

                        this.refresh = true;
                        setTimeout(() => {
                            this.refresh = false;
                        }, 50);
                    }
                } else if (
                    val.length == 1 &&
                    Object.keys(this.lotPremiums).length == 0
                ) {
                    let premium = JSON.parse(JSON.stringify(val));
                    this.lotPremiums[premium[0].id] = premium[0];
                    this.refresh = true;
                    setTimeout(() => {
                        this.refresh = false;
                    }, 50);
                }
            },
        },
        storeConditions: {
            handler(val) {
                if (
                    this.lotConditions &&
                    Object.keys(this.lotConditions).length
                ) {
                    if (val.length > Object.keys(this.lotConditions).length) {
                        let conditions = Object.keys(this.lotConditions);
                        JSON.parse(JSON.stringify(val)).forEach((x) => {
                            if (!conditions.includes(x.id)) {
                                this.lotConditions[x.id] = {
                                    name: x.name,
                                    price: x.price,
                                    active: x.active,
                                };
                            }
                        });
                        this.refresh = true;
                        setTimeout(() => (this.refresh = false), 10);
                    }
                } else if (
                    val.length == 1 &&
                    Object.keys(this.lotConditions).length == 0
                ) {
                    let condition = JSON.parse(JSON.stringify(val));
                    this.lotConditions[condition[0].id] = condition[0];
                    this.refresh = true;
                    setTimeout(() => (this.refresh = false), 10);
                }
            },
        },
        parentLot: {
            handler(val) {
                if (val.inherit != "") {
                    if (val.inherit != "none") {
                        if (
                            Object.keys(this.$store.state.siteplan.children)
                                .length != 0
                        ) {
                            let chosenLot = {};
                            let chosenInstance = {};
                            let children = JSON.parse(
                                JSON.stringify(
                                    Object.values(
                                        this.$store.state.siteplan.children
                                    )
                                )
                            );

                            children.forEach((instance) => {
                                if (
                                    instance.siteplan &&
                                    instance.siteplan.lots
                                ) {
                                    instance.siteplan.lots.forEach((lot) => {
                                        if (lot.id == val.inherit) {
                                            chosenLot = lot;
                                            chosenInstance = instance;
                                        }
                                    });
                                }
                            });
							if (Object.keys(chosenLot).length > 0){
								let tempTags = [];

								chosenLot.tags.forEach((tag) => {
									chosenInstance.tags.forEach((instanceTag) => {
										if (
											instanceTag.id == tag ||
											instanceTag.name == tag
										) {
											let tempTag = instanceTag.name;
											tempTags.push(tempTag);
										}
									});
								});

								let tempUnits = [];
								chosenLot.units.forEach((id) => {
									tempUnits.push(id);
								});

								if (this.dialog.type == "edit") {
									let editLot = JSON.parse(
										JSON.stringify(
											this.$store.state.siteplan.editingLot
										)
									);
									if (editLot.other && !editLot.other.customFields) {
										editLot.other.customFields = {};
									}
									if (
										!editLot.hasOwnProperty("right") ||
										editLot.right == "" ||
										editLot.right == null
									) {
										chosenLot.right = "none";
									} else if (
										editLot.right &&
										typeof editLot.right == "object"
									) {
										chosenLot.right = editLot.right.id;
									} else if (
										editLot.right &&
										editLot.right != "" &&
										typeof editLot.right == "string"
									) {
										chosenLot.right = editLot.right;
									}

									if (
										!editLot.hasOwnProperty("left") ||
										editLot.left == "" ||
										editLot.left == null
									) {
										chosenLot.left = "none";
									} else if (
										editLot.left &&
										typeof editLot.left == "object"
									) {
										chosenLot.left = editLot.left.id;
									} else if (
										editLot.left &&
										editLot.left != "" &&
										typeof editLot.left == "string"
									) {
										chosenLot.left = editLot.left;
									}

									if (
										!editLot.hasOwnProperty("front") ||
										editLot.front == "" ||
										editLot.front == null
									) {
										chosenLot.front = "none";
									} else if (
										editLot.front &&
										typeof editLot.front == "object"
									) {
										chosenLot.front = editLot.front.id;
									} else if (
										editLot.front &&
										editLot.front != "" &&
										typeof editLot.front == "string"
									) {
										chosenLot.front = editLot.front;
									}

									chosenLot.shape = editLot.shape;
									chosenLot.shapeId = editLot.shapeId;
									if (typeof editLot.siteplan == "object") {
										chosenLot.siteplan = editLot.siteplan.id;
									} else {
										chosenLot.siteplan = editLot.siteplan;
									}
									chosenLot.id = editLot.id;
								} else {
									if (
										!chosenLot.hasOwnProperty("right") ||
										chosenLot.right == "" ||
										chosenLot.right == null
									)
										chosenLot.right = "none";
									else if (
										chosenLot.right &&
										chosenLot.right != ""
									) {
										chosenLot.right = "none";
									}

									if (
										!chosenLot.hasOwnProperty("left") ||
										chosenLot.left == "" ||
										chosenLot.left == null
									)
										chosenLot.left = "none";
									else if (
										chosenLot.left &&
										chosenLot.left != ""
									) {
										chosenLot.left = "none";
									}

									if (
										!chosenLot.hasOwnProperty("front") ||
										chosenLot.front == "" ||
										chosenLot.front == null
									)
										chosenLot.front = "none";
									else if (
										chosenLot.front &&
										chosenLot.front != ""
									) {
										chosenLot.front = "none";
									}

									chosenLot.shape = this.lot.shape;
									chosenLot.shapeId = this.lot.shapeId;
									chosenLot.siteplan = this.lot.siteplan;
									chosenLot.type = this.lot.type;
								}

								if (chosenLot.other && !chosenLot.other.customFields) {
									chosenLot.other.customFields = {};
								}

								if(chosenLot.closingDate){
									chosenLot.closingDate = moment(chosenLot.closingDate)
								}
								chosenLot.tags = tempTags;
								chosenLot.units = tempUnits;
								if (!chosenLot.condition)
									chosenLot.condition = ["none"];
								this.lot = chosenLot;
							} else {
								console.log('ELSE RAN')
								this.parentLot.inherit = ""
								this.$message.error('Inherited lot no longer exists. Please select a new one.');
							}
                        }
                    } else {
                        if (this.dialog.type == "add") {
                            let lot = {
                                name: "",
                                image: "",
                                left: "none",
                                block: "",
                                front: "none",
                                right: "none",
                                siteplan: this.$store.state.siteplan.appData.id,
                                description: "",
                                type: "lot",
                                premiums: [], //
                                shapeId: this.lot.shapeId,
                                shape: this.lot.shape,
                                units: [],
                                tags: [],
                                unitType: "",
                                street: "",
								street2: "",
                                streetNumber: "",
								streetNumber2: "",
                                postal: "",
								postal2: "",
								city:"",
								region:"",
								legalDescription:"",
                                status: "available",
                                garageSide: "none",
								other: { customFields : {}},
                                frontRiserMin: 0,
                                frontRiserMax: 0,
                                garageRiserMin: 0,
                                garageRiserMax: 0,
                                wob: true,
                                rearRiserMin: 0,
                                rearRiserMax: 0,
                                condition: "none",
                                notes: "",
                                uniqueGrades: [],
								closingDate: null,
								dynamicClosingDate: false,
								dynamicClosingDateUnit: "days",
								dynamicClosingDateValue: 0,
								deactivateArchitecturalRules: false,
								media: {
									mediaImage: [],
								}
                            };
                            this.lot = lot;
                        } else {
                            if (!this.savedEditLot.condition)
                                this.savedEditLot.condition = ["none"];
                            this.lot = this.savedEditLot;
							if (this.lot.other && !this.lot.other.customFields) {
								this.lot.other.customFields = {};
							}
                            let types = [];
                            let frontages = [];
                            if (
                                this.lot &&
                                this.lot.units &&
                                this.lot.units.length
                            ) {
                                this.lot.units.forEach((x) => {
                                    if (
                                        this.allUnits.hasOwnProperty(x) &&
                                        this.allUnits[x].unitGroup
                                    ) {
                                        if (
                                            !types.includes(
                                                this.allUnits[x].unitGroup.type
                                            )
                                        )
                                            types.push(
                                                this.allUnits[x].unitGroup.type
                                            );
                                        if (
                                            !frontages.includes(
                                                Number(this.allUnits[x].unitGroup.size)
                                            )
                                        )
                                            frontages.push(
                                                Number(this.allUnits[x].unitGroup.size)
                                            );
                                    }
                                });
                                if (
                                    types.length > 1 ||
                                    (types.length == 1 &&
                                        types[0] != this.lot.modelType)
                                )
                                    this.differentTypes = true;
                                // if (
                                //     frontages.length > 1 ||
                                //     (frontages.length == 1 &&
                                //         frontages != this.lot.size)
                                // )
                                //     this.differentFrontages = true;
                            }
                        }
                    }
                }
            },
            deep: true,
        },
        activeKey(val) {
            this.openNewFloor = val.key + 0;
        },
        dialog: {
            handler(val) {
                if (!val.show) {
                    this.standingInventory = false;
                    this.lotPremiums = {};
                    this.lotGradings = {};
                    this.deleting = false;
                    this.deletePopup = false;
                    this.activeKey = "1";
                    this.loading = false;
                    this.lotUnitType = "";
                    (this.newGrading = {
                        name: "",
                        value: "",
                        active: false,
                    }),
                        (this.newPremium = {
                            name: "",
                            value: "",
                            active: false,
                        }),
                        (this.parentLot.inherit = "");
                    this.lot = JSON.parse(JSON.stringify(this.defaultLot));
					this.selectedLot = "none";
                } else {
                    if (val.type === "add") {
                        this.savedEditLot = {};
                        this.computeSettings();
                        this.lot.shapeId = String(this.pointInfo.point.id);
                        this.lot.shape = this.pointInfo.point;
                        this.parentLot.inherit = "none";
                        this.selectedLot = "none";

                        this.lot.publishStatus = "draft";
                    }
                    if (val.type === "edit") {
                        let obj = JSON.parse(
                            JSON.stringify(
                                this.$store.state.siteplan.editingLot
                            )
                        );
						console.log('VAL', obj)
						if (obj.units && obj.units.length){
							obj.units = obj.units.filter((x) => this.allUnits[x.id ? x.id : x])
						}
						if (!obj.other){
							obj.other = {
								customFields:{},
								gradings:{},
								premiums:{}
							}
							this.computeSettings();
						}
                        this.updateToSelectedLot(obj)
                    }
                }
                this.newTime = Date.now() + "";
            },
            deep: true,
        },

		selectedLot(val) {
			if (val !== 'none') {
				let lot = this.lotsMap[val];

				if (lot.shapeId) {
					let self = this;
					this.$confirm({
						title: "Already Linked",
						content: (h) => (
							<div>
								Selected lot already linked with other Polygon shape. Do you want to replace it with the current shape?
							</div>
						),
						okText: "REPLACE",
						okType: "primary",
						cancelText: "CANCEL",
						centered: true,
						onOk() {
							lot = {
								...self.lot,
								...lot,
								shapeId: String(self.pointInfo.point.id),
								shape: self.pointInfo.point,
								closingDate: lot.closingDate ? moment(lot.closingDate) : null,
							}
							if (lot.other && !lot.other.customFields) {
								lot.other.customFields = {};
							}
							self.updateToSelectedLot(lot)
						},
						onCancel(){
							self.selectedLot = 'none'
						}
					});
				} else {
					lot = {
						...this.lot,
						...lot,
						closingDate: lot.closingDate ? moment(lot.closingDate) : null,
						shapeId: String(this.pointInfo.point.id),
						shape: this.pointInfo.point
					}
					if (lot.other && !lot.other.customFields) {
						lot.other.customFields = {};
					}
					this.updateToSelectedLot(lot)
				}
			} else {
				this.lot = JSON.parse(JSON.stringify(this.defaultLot));
				if (this.lot.other && !this.lot.other.customFields) {
					this.lot.other.customFields = {};
				}
				if(this.pointInfo && this.pointInfo.point){
					this.lot.shapeId = String(this.pointInfo.point.id);
					this.lot.shape = this.pointInfo.point;
				}
				this.parentLot.inherit = "none";

				this.lot.publishStatus = "draft";
			}
			this.newTime = Date.now() + "";
		},

		customFields: {
			handler(val) {
				if (val) {
					const fields = this.updateCustomFields(val);
					if (
						this.savedEditLot.other &&
						this.savedEditLot.other.customFields
					)
						this.savedEditLot.other.customFields = fields;
					this.refresh2 = true;
					setTimeout(() => {
						this.refresh2 = false
					},10)
				}
			}
		},
    },
    computed: {
        lotNames() {
            return this.$store.getters.lotNames;
        },
        typeCheck() {
            let hasUnits = Boolean(this.lot && this.lot.units && this.lot.units.length);
            let type = this.lot.modelType && this.lot.modelType.length;
            return {
                status: type || hasUnits ? "success" : "error",
                msg: type || hasUnits ? "" : "Please enter a type",
            };
        },
        sizeCheck() {
            let hasUnits = Boolean(this.lot && this.lot.units && this.lot.units.length);
            let size = this.lot.size && this.lot.size.toString().length;
            return {
                status: size || hasUnits ? "success" : "error",
                msg: size || hasUnits ? "" : "Please enter a size",
            };
        },
        title() {
            return this.selectedLot !== "none" ? "Map To Existing Lot" : this.dialog.type === "add" ? "Add New Lot" : "Edit Lot";
        },
        childUnits() {
            return this.$store.state.siteplan.childUnits;
        },
        storeGradings() {
            if (
                this.$store.state.siteplan.allSettings &&
                this.$store.state.siteplan.allSettings.app &&
                this.$store.state.siteplan.allSettings.app.options &&
                this.$store.state.siteplan.allSettings.app.options.gradings
            ) {
                return this.$store.state.siteplan.allSettings.app.options
                    .gradings;
            }
            return [];
        },
        storePremiums() {
            return this.$store.getters.lotPremiums;
        },
        storeConditions() {
            return this.$store.state.siteplan.allSettings?.app?.options?.conditions || [
                {
                    active: false,
                    id: "WOD",
                    name: "Walk-out deck",
                    price: null,
                },
            ];
        },
        hasChildren() {
            if (
                Object.keys(this.$store.state.siteplan.children).length != 0 &&
                Object.keys(this.$store.state.siteplan.childLots).length != 0
            )
                return true;
            else return false;
        },
        childrenLots() {
            let children = [];
            let usedLots = [];
            let storeLots = Object.values(this.$store.state.siteplan.lots);

            storeLots.forEach((lot) => {
                if (
                    lot.hasOwnProperty("inherit") &&
                    lot.inherit != "" &&
                    !usedLots.includes(lot.inherit)
                ) {
                    usedLots.push(lot.inherit);
                }
            });

            if (Object.keys(this.$store.state.siteplan.children).length != 0) {
                Object.values(this.$store.state.siteplan.children).forEach(
                    (instance) => {
                        let child = {
                            name: "",
                            id: "",
                            lots: [],
                        };
                        child.name = instance.name;
                        child.id = instance.id;

                        if (
                            instance.siteplan != null &&
                            instance.siteplan.lots &&
                            instance.siteplan.lots.forEach
                        ) {
                            instance.siteplan.lots.forEach((instanceLot) => {
                                if (this.dialog.type == "add") {
                                    if (
                                        instanceLot.type == "lot" &&
                                        !usedLots.includes(instanceLot.id)
                                    ) {
                                        let lot = instanceLot;
                                        child.lots.push(lot);
                                    }
                                } else if (this.dialog.type == "edit") {
                                    if (instanceLot.type == "lot") {
                                        let lot = instanceLot;
                                        child.lots.push(lot);
                                    }
                                }
                            });
                        }

                        children.push(child);
                    }
                );
            }

            return children;
        },
        streetName() {
            let streets = [];
            let lots = Object.values(this.$store.state.siteplan.lots).forEach(
                (lot) => {
                    if (lot.street && !streets.includes(lot.street)) {
                        streets.push(lot.street);
                    }
					if (lot.street2 && !streets.includes(lot.street2)) {
                        streets.push(lot.street);
                    }
                }
            );
            return streets;
        },
        lotsMap() {
            return this.$store.state.siteplan.lots;
        },
        lots() {
            let lotList = [];
            Object.values(this.$store.state.siteplan.lots).forEach((lot) => {
                if (
                    (lot.type != "info" &&
                        this.$store.state.siteplan.editingLot &&
                        lot.id != this.$store.state.siteplan.editingLot.id &&
                        this.dialog.type == "edit") ||
                    (this.dialog.type == "add" && lot.type != "info")
                )
                    lotList.push(lot);
            });
            let list = lotList.map(({ name, block, id: value, inherit }) => {
                if (inherit && inherit != "") {
                    let lot = {};
                    if (typeof inherit == "object") {
                        lot.name = this.childLots[inherit.id].name + `${this.childLots[inherit.id]?.block ? ` (Block ${this.childLots[inherit.id].block})` : ''}`;
                    } else if (
                        this.childLots[inherit] &&
                        this.childLots[inherit].name
                    ) {
                        lot.name = this.childLots[inherit].name + `${this.childLots[inherit]?.block ? ` (Block ${this.childLots[inherit].block})` : ''}`;
                    }
                    lot.value = value;
                    return lot;
                } else {
					name = name + `${block ? ` (Block ${block})` : ''}`
                    return { name, value };
                }
            });
            let noneObj = {
                name: "None",
                value: "none",
            };
            list.push(noneObj);
            return list;
        },
        childLots() {
            return this.$store.state.siteplan.childLots;
        },
        pointInfo() {
            return this.$store.state.siteplan.newLotInfo;
        },
        tags() {
            return this.$store.state.siteplan.tags;
        },
        objTags() {
            let obj = {};
            this.tags.forEach((tag) => {
                obj[tag.id] = tag;
            });
            return obj;
        },
        instance() {
            return this.$store.state.instance;
        },
        appData() {
            return this.$store.state.siteplan.appData;
        },
        getTitle() {
            return `Create a new `;
        },
        dialog() {
            return this.$store.state.siteplan.lotDrawer;
        },
        unitsFiltered() {
            if (!this.lotUnitType) return this.units;
            return this.units.filter((x) => x.key === this.lotUnitType);
        },
        allUnits() {
            let obj = {};
            if (
                !this.$store.state.siteplan.units ||
                !this.$store.state.siteplan.units.length
            )
                return {};
            this.$store.state.siteplan.units.forEach((x) => (obj[x.id] = x));
            return obj;
        },
        selectUnits() {
            if (
                this.parentLot.inherit !== "" &&
                this.parentLot.inherit !== "none"
			) {
				return Object.values(this.$store.state.siteplan.childGroups).map(
					(uG) => {
						return {
							title: uG.name + ` (${uG.size}\' ${uG.type})`,
							value: uG.id,
							key: uG.id,
							children: uG.units
								.filter((x) => this.childUnits[x.id ? x.id : x])
								.map((unitId) => {
									let unit = this.childUnits[unitId];
									return {
										title: uG.name + " " + unit.name,
										value: unit.id,
										key: unit.id,
									};
								}),
						};
					}
				);
			}

            return Object.values(this.$store.state.siteplan.unitGroups).map(
                (uG) => {
                    return {
                        title: uG.name + ` (${uG.size}\' ${uG.type})`,
                        value: uG.id,
                        key: uG.id,
                        children: uG.units
                            .filter((x) => this.allUnits[x.id ? x.id : x])
                            .map((unitId) => {
                                let unit = this.allUnits[unitId];
                                return {
                                    title: uG.name + " " + unit.name,
                                    value: unit.id,
                                    key: unit.id,
                                };
                            }),
                    };
                }
            );
        },
        units() {
            let storeGroups = this.$store.state.siteplan.unitGroups || [];
            let groups = storeGroups.filter((x) => x.unitType);
            return groups.map((uG) => {
                return {
                    title: uG.name,
                    value: uG.id,
                    key: uG.unitType.id,
                    children: uG.units.map((u) => ({
                        title: `${uG.name} - ${u.name}`,
                        value: u.id,
                        key: u.id,
                    })),
                };
            });
        },
        types() {
            let types = [];
            this.$store.state.siteplan.unitGroups.forEach((uG) => {
                if (!types.includes(uG.type)) types.push(uG.type);
            });
            return types;
        },
        unitTypes() {
            let unitTypes = this.$store.state.siteplan.unitTypes;
            return unitTypes.map((uT) => ({
                label: `${uT.name} - ${uT.size}'`,
                value: uT.id,
            }));
        },
        storeUnits() {
            let units = {};
            this.$store.state.siteplan.units.forEach((unit) => {
                units[unit.id] = unit;
            });
            return units;
        },
        country() {
            let { country = "" } = this.instance;
            if (!country) country = "";
            country = country.toLowerCase().trim();
            return country === "canada" || country === "ca" ? "CA" : "USA";
        },
		customFields() {
			return Object.values(this.$store.state.siteplan.customFields)
		},
    },
    methods: {
		updateToSelectedLot(lot){
			let obj = lot
			if (obj.status == "inventory") {
                this.standingInventory = true;
				// console.log(lot,'lot to edit')
            } else {
                this.standingInventory = false;
            }
            if (!obj.publishStatus) obj.publishStatus = "draft";
            if (obj.tags && obj.tags.length) {
                obj.tags = obj.tags.map((x) => {
                    if (x.name) {
                        return x.name;
                    } else {
                        let tag =
                            this.$store.state.siteplan.tags.find(
                                (t) => t.id === x
                            );
                        if (tag) return tag.name;
                    }
                });
            }

            if (!obj.attachedLeft) obj.attachedLeft = false;
            if (!obj.attachedRight) obj.attachedRight = false;
            // else if (
            //     obj.tags &&
            //     obj.tags.length &&
            //     typeof obj.tags[0] == "string"
            // ) {
            //     let array = [];
            //     let spliceIs = [];
            //     obj.tags.forEach((tag, tagI) => {
            //         if (this.objTags[tag]) {
            //             array.push(this.objTags[tag].name);
            //         }
            //     });
            //     obj.tags = array;
            // }
            if (!obj.left) obj.left = "none";
            if (!obj.front) obj.front = "none";
            if (!obj.right) obj.right = "none";
            if (obj.right.id) obj.right = obj.right.id;
            if (obj.left.id) obj.left = obj.left.id;
            if (obj.front.id) obj.front = obj.front.id;
            if (!this.lotsMap[obj.left]) obj.left = "none";
            if (!this.lotsMap[obj.right]) obj.right = "none";
            if (!this.lotsMap[obj.front]) obj.front = "none";

            if (obj.inherit) {
                if (typeof obj.inherit == "object") {
                    this.parentLot.inherit = obj.inherit.id;
                } else if (
                    typeof obj.inherit == "string" &&
                    obj.inherit != ""
                ) {
                    this.parentLot.inherit = obj.inherit;
                }
            } else {
                this.parentLot.inherit = "none";
                let arrayOfProperties = [
                    "siteplan",
                    "unitType",
                    "left",
                    "right",
                    "front",
                ];
                arrayOfProperties.forEach((x) => {
                    if (!obj.hasOwnProperty(x)) {
                        return;
                    }
                    if (typeof obj[x] === "object") {
                        obj[x] = obj[x].id;
                    }

                    if (
                        typeof obj.units === "object" &&
                        obj.units.length &&
                        typeof obj.units[0] === "object"
                    )
                        obj.units = obj.units.map((x) => x.id);
                });

                let tags = [];
                obj.tags.forEach((tag) => {
                    if (typeof tag == "object") {
                        tags.push(tag.id);
                    } else if (typeof tag == "string") {
                        tags.push(tag);
                    }
                });

				if(obj.closingDate){
					obj.closingDate = obj.closingDate && moment(obj.closingDate)
				}
                obj.tags = tags;
                this.lot = JSON.parse(
                    JSON.stringify(this.defaultLot)
                );
                $.extend(true, this.lot, obj);
                if (
                    !obj.right ||
                    obj.right == "" ||
                    obj.right == null
                )
                    obj.right = "none";
                if (!obj.left || obj.left == "" || obj.left == null)
                    obj.left = "none";
                if (
                    !obj.front ||
                    obj.front == "" ||
                    obj.front == null
                )
                    obj.front = "none";
                this.savedEditLot = obj;
            }
            if (!obj.condition) obj.condition = ["none"];
            this.computeSettings();
		},

        checkDefault(index, obj) {
            if (obj == "lotGradings") {
                if (
                    this.savedEditLot.other &&
                    this.savedEditLot.other.gradings &&
                    this.savedEditLot.other.gradings.hasOwnProperty(index)
                ) {
                    this.lotGradings[index].active =
                        this.savedEditLot.other.gradings[index].active;
                    return this.savedEditLot.other.gradings[index].active;
                }
                return false;
            } else if (obj == "lot.other.gradings") {
                if (
                    this.savedEditLot.other &&
                    this.savedEditLot.other.gradings &&
                    this.savedEditLot.other.gradings.hasOwnProperty(index)
                ) {
                    this.lot.other.gradings[index].active =
                        this.savedEditLot.other.gradings[index].active;
                    return this.savedEditLot.other.gradings[index].active;
                }
                return false;
            } else if (obj == "lotPremiums") {
                if (
                    this.savedEditLot.other &&
                    this.savedEditLot.other.premiums &&
                    this.savedEditLot.other.premiums.hasOwnProperty(index)
                ) {
                    this.lotPremiums[index].active =
                        this.savedEditLot.other.premiums[index].active;
                    return this.savedEditLot.other.premiums[index].active;
                }
                return false;
            } else if (obj == "lot.other.premiums") {
                if (
                    this.savedEditLot.other &&
                    this.savedEditLot.other.premiums &&
                    this.savedEditLot.other.premiums.hasOwnProperty(index)
                ) {
                    this.lot.other.premiums[index].active =
                        this.savedEditLot.other.premiums[index].active;
                    return this.savedEditLot.other.premiums[index].active;
                }
                return false;
            } else if (obj == "lotConditions") {
                if (
                    this.savedEditLot.other &&
                    this.savedEditLot.other.conditions &&
                    this.savedEditLot.other.conditions.hasOwnProperty(index)
                ) {
                    this.lotConditions[index].active =
                        this.savedEditLot.other.conditions[index].active;
                    return this.savedEditLot.other.conditions[index].active;
                }
                return false;
            } else if (obj == "lot.other.conditions") {
                if (
                    this.savedEditLot.other &&
                    this.savedEditLot.other.conditions &&
                    this.savedEditLot.other.conditions.hasOwnProperty(index)
                ) {
                    this.lot.other.conditions[index].active =
                        this.savedEditLot.other.conditions[index].active;
                    return this.savedEditLot.other.conditions[index].active;
                }
                return false;
            }
        },
        updateCheckbox(e, index, obj) {
            if (obj == "lotGradings") {
                this.lotGradings[index].active = e.target.checked;
            } else if (obj == "lot.other.gradings") {
                this.lot.other.gradings[index].active = e.target.checked;
            }
        },
        deleteButton() {
            if (this.$p < 40)
                return this.$message.error(
                    "You do not have permission to delete this lot."
                );
            let self = this;
            this.$confirm({
                title: "Bulk Delete",
                content: (h) => (
                    <div>
                        Do you want to delete this Site Plan Lot? Deleting will
                        permanently remove the Site Plan Lot
                    </div>
                ),
                okText: "Delete",
                okType: "danger",
                cancelText: "Cancel",
                centered: true,
                onOk() {
                    self.confirmDelete();
                },
            });
        },
		updateCustomFields(cFields) {
			let fields = {}
			JSON.parse(JSON.stringify(cFields)).forEach(field => {
				fields[field.id] = field.type === 'number' ? Number(field.options.default) : field.type === 'date' ? moment(field.options.default) : field.options.default
			})
			if (!this.lot.other) {
				this.lot.other = {
					customFields: fields
				}
			} else if (!this.lot.other.customFields) {
				this.$set(this.lot.other, 'customFields', fields)
			} else if (this.lot.other.customFields) {
				let fields = {}
				JSON.parse(JSON.stringify(cFields)).forEach(field => {
					if (this.lot.other.customFields[field.id]) {
						fields[field.id] = this.lot.other.customFields[field.id]
					} else {
						fields[field.id] = field.type === 'number' ? Number(field.options.default) : field.type === 'date' ? moment(field.options.default) : field.options.default
					}
				})
				this.$set(this.lot.other, 'customFields', fields)
			}

			return fields;
		},
        computeSettings() {
            let premiums = [];
            let gradings = [];

            let premiumMap = {};
            let gradingMap = {};

            if (
                this.lot.hasOwnProperty("other") &&
                this.lot.other &&
                this.lot.other.premiums &&
                Object.keys(this.lot.other.premiums).length
            )
                premiums = Object.keys(this.lot.other.premiums);
            if (
                this.lot.hasOwnProperty("other") &&
                this.lot.other &&
                this.lot.other.gradings &&
                Object.keys(this.lot.other.gradings).length
            )
                gradings = Object.keys(this.lot.other.gradings);

            JSON.parse(JSON.stringify(this.storePremiums)).forEach(
                (premium) => {
                    if (premiums.includes(premium.id)) {
                        // lot already has this premium
                        premiumMap[premium.id] =
                            this.lot.other.premiums[premium.id];
                        if (
                            (this.lot.other.premiums[premium.id].price ==
                                null ||
                                this.lot.other.premiums[premium.id].price ==
                                    0) &&
                            premium.price != null
                        ) {
                            premiumMap[premium.id].price = premium.price;
                        }
                        if (premiumMap[premium.id].price == null)
                            premiumMap[premium.id].price = 0;
                    } else {
                        premiumMap[premium.id] = premium;
                        if (premium.price == null) {
                            premiumMap[premium.id].price = 0;
                        }
                    }
                }
            );

            JSON.parse(JSON.stringify(this.storeGradings)).forEach(
                (grading) => {
                    if (gradings.includes(grading.id)) {
                        // lot already has this grading
                        gradingMap[grading.id] =
                            this.lot.other.gradings[grading.id];
                    } else {
                        gradingMap[grading.id] = grading;
                    }
                }
            );

			if (this.customFields) {
				this.updateCustomFields(this.customFields)
			}

            this.lotPremiums = premiumMap;
            this.lotGradings = gradingMap;
        },
        onSearch(searchText) {
            let temp = [];
            this.streetName.forEach((street) => {
                temp.push(street);
            });
            let unique = [...new Set(temp)];
            if (searchText && searchText.length > 0) {
                this.dataSource = unique.filter((x) => {
                    x = x.toLowerCase();
                    searchText = searchText.toLowerCase();
                    return x.includes(searchText);
                });
            } else {
                return (this.dataSource = []);
            }
        },
        noreq: (msg) => ({ required: false }),
        req: (msg, required = true) => ({ required: required, message: msg }),
        updateUnits(e) {
            this.lotUnitType = e;
            this.lot.units = [];
        },
		disabledDate(current) {
            return current && current < moment().startOf('day')
        },
        formatNumber,
        deletePremium(item, index) {
            let id = item.id;
            this.lot.premiums.splice(index, 1);
            this.lot.premiums = this.lot.premiums.filter((x) => x.id !== id);
        },
        deleteGrading(item, index) {
            let id = item.id;
            this.lot.uniqueGrades.splice(index, 1);
            this.lot.uniqueGrades = this.lot.uniqueGrades.filter(
                (x) => x.id !== id
            );
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
            );
        },
        addNewPremium() {
            this.$store.commit("OPEN_SETTINGS_MODAL", "premium");
        },
        addNewGrading() {
            this.$store.commit("OPEN_SETTINGS_MODAL", "grading");
        },

        onUnitSelect(val) {
            this.differentFrontages = false;
            this.differentTypes = false;
            if (val.length && this.allUnits[val[0]] && this.allUnits[val[0]].unitGroup) {
                this.lot.units = val;
				let size = 0;
				val.forEach((id) => {
					if (this.allUnits[id].unitGroup.size > size) {
						size = this.allUnits[id].unitGroup.size;
					}
				});
                // let size = this.allUnits[val[0]].unitGroup.size;
                let type = this.allUnits[val[0]].unitGroup.type;
                let error = false;
                val.forEach((id) => {
                    if (
                        this.allUnits[id].unitGroup.type != type
                    ) {
                        this.lot.units = [];
                        this.lot.modelType = "";
                        this.lot.size = "";
                        size = "";
                        type = "";
                        error = true;
                    }
                });
                if (error)
                    return this.$message.error("Mismatch model types");

                if (type != "" && size != "") {
                    this.lot.modelType = type;
                    this.lot.size = size.toString();
                }
            } else {
                this.lot.units = [];
            }
        },
        confirmDelete() {
            this.loading = true;
            this.$api
                .delete(
                    `/lots/${this.instance.id}/${this.appData.id}/${this.lot.id}`
                )
                .then(({ data }) => {
                    this.loading = false;
                    this.onClose({ type: "delete", data });
                    this.$store.commit("DELETE_LOT", data);
                }).catch(err => {
					this.loading = false;
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        submitButton() {
            if (this.dialog.type != "add") {
                if (
					this.parentLot.inherit == 'none' &&
					this.lot.status &&
                    this.lot.status != "available" &&
                    this.lot.status != "inventory" &&
                    this.lot.status != "notreleased"
                ) {
                    return this.$message.error(
                        "You cannot update lots that are " +
                            (this.lot.status && this.lot.status.includes("sold")
                                ? "sold"
                                : "on hold")
                    );
                }
                // if (this.differentFrontages)
                //     return this.$message.error(
                //         "You cannot update lots that contain models of different frontages"
                //     );
                if (this.differentTypes)
                    return this.$message.error(
                        "You cannot update lots that contain models of different types"
                    );
            }
            if (this.$p < 20 && this.lot.publishStatus === "published") {
                return this.$message.error(
                    "You do not have permission to modify published lots"
                );
            }
            if (this.standingInventory) {
                if (this.lot && this.lot.units && this.lot.units.length != 1) {
                    return this.$message.error(
                        "Please select only one unit for this standing inventory lot."
                    );
                }
            }
            if (this.dialog.type === "add" && this.selectedLot === "none") return this.createLot();
            return this.updateLot();
        },
        tagsToIds(obj) {
            let tags = obj.tags || [];

            obj.newTags = [];
            let oldTags = [];

            let self = this;

            tags.forEach((tID) => {
                if (self.tags.find((x) => x.name === tID)) {
                    let found = self.tags.find((x) => x.name === tID);
                    oldTags.push(found.id);
                } else obj.newTags.push(tID);
            });
            obj.tags = oldTags;

            return obj;
        },
        validateGradings() {
            let index = this.lot.uniqueGrades.findIndex(
                (x) =>
                    (x.value == null && x.name.trim()) ||
                    (x.value != null && !x.name.trim())
            );
            if (index != -1)
                return this.$message.error(
                    `Custom Grading ${index + 1} is missing a Name or a Price`
                );

            for (var i = 0; i < this.lot.uniqueGrades.length; i++) {
                if (
                    !this.lot.uniqueGrades[i].name.trim() &&
                    this.lot.uniqueGrades[i].value == null
                ) {
                    this.lot.uniqueGrades.splice(i, 1);
                }
            }
        },
        validatePremiums() {},
        createLot() {
			console.log('CREATE LOT', this.lot)
            if (!this.hasChildren || this.parentLot.inherit == "none") {
                if (this.lotNames.includes(this.lot.name))
                    return this.$message.error("This lot already exists");
                if (
                    this.newGrading.name.trim() &&
                    !this.newGrading.value.trim()
                )
                    this.$message.error("Please enter the new grading value");
                if (
                    this.newGrading.value.trim() &&
                    !this.newGrading.name.trim()
                )
                    this.$message.error("Please enter the new grading name");
                if (
                    this.newPremium.name.trim() &&
                    !this.newPremium.value.trim()
                )
                    this.$message.error("Please enter the new premium value");
                if (
                    this.newPremium.value.trim() &&
                    !this.newPremium.name.trim()
                )
                    this.$message.error("Please enter the new premium name");

                let index = this.lot.uniqueGrades.findIndex(
                    (x) =>
                        (!x.value.trim() && x.name.trim()) ||
                        (x.value.trim() && !x.name.trim())
                );
                if (index != -1)
                    return this.$message.error(
                        `Custom Grading ${
                            index + 1
                        } is missing a Name or a Price`
                    );

                for (var i = 0; i < this.lot.uniqueGrades.length; i++) {
                    if (
                        !this.lot.uniqueGrades[i].name.trim() &&
                        !this.lot.uniqueGrades[i].value.trim()
                    ) {
                        this.lot.uniqueGrades.splice(i, 1);
                    }
                }
                this.$refs.lot.validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        if (this.lot.right == "none") this.lot.right = null;
                        if (this.lot.left == "none") this.lot.left = null;
                        if (this.lot.front == "none") this.lot.front = null;

                        if (
                            this.newGrading.name.trim() &&
                            this.newGrading.value.trim()
                        )
                            this.lot.uniqueGrades.push(this.newGrading);

                        if (
                            this.newPremium.name.trim() &&
                            this.newPremium.value.trim()
                        )
                            this.lot.premiums.push(this.newPremium);
                        this.lot.uniqueGrades = this.lot.uniqueGrades.map(
                            (x) => ({
                                name: x.name,
                                value: x.value,
                                active: x.active,
                            })
                        );
                        this.lot.premiums = this.lot.premiums.map((x) => ({
                            name: x.name,
                            value: x.value,
                            active: x.active,
                        }));
                        Object.values(this.lotPremiums).forEach((x) => {
                            x.price = parseFloat(x.price);
                        });
                        Object.values(this.lotGradings).forEach((x) => {
                            x.price = parseFloat(x.price);
                        });

						if (!this.lot.other) {
							this.lot.other = {};
						}

						this.lot.other.premiums = this.lotPremiums;
						this.lot.other.gradings = this.lotGradings;
						this.lot.other.customFields = this.lot.other.customFields || {};

                        if (this.lot.size && this.lot.size != "")
                            this.lot.size = Number(this.lot.size);
                        let sendObj = JSON.parse(JSON.stringify(this.lot));
                        if (this.standingInventory) {
                            sendObj.status = "inventory";
                        } else {
                            sendObj.status = "available";
                        }
						sendObj.closingDate = sendObj.closingDate && (moment(sendObj.closingDate).format('X') * 1000)
                        let street = sendObj.street && sendObj.street.trim();
                        sendObj.street = street;
                        sendObj = this.tagsToIds(sendObj);
                        if (sendObj.siteplan.id && sendObj.siteplan.id != "")
                            sendObj.siteplan = sendObj.siteplan.id;

                        this.$api
                            .post(
                                `/lots/${this.instance.id}/${sendObj.siteplan}`,
                                sendObj
                            )
                            .then(async ({ data }) => {
                                this.loading = false;
                                await this.onClose({ type: "add", data });
                                this.$store.commit(
                                    "OPEN_LEFTNAV",
                                    data.shapeId
                                );
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							}).finally(() => {
								this.loading = false;
							})
                    } else {
                        return false;
                    }
                });
            } else if (this.hasChildren && this.parentLot.inherit != "none") {
                this.$refs.parentLot.validate((valid) => {
                    if (valid) {
                        this.$refs.lot.validate((valid) => {
                            if (valid) {
                                let lot = JSON.parse(JSON.stringify(this.lot));

                                let sendObj = {};
                                if (this.standingInventory) {
                                    sendObj.status = "inventory";
                                } else {
                                    sendObj.status = "available";
                                }
                                if (sendObj.size && sendObj.size != "")
                                    sendObj.size = Number(sendObj.size);
                                sendObj.inherit = this.parentLot.inherit;
                                if (lot.front == "none") lot.front = "";
                                if (lot.left == "none") lot.left = "";
                                if (lot.right == "none") lot.right = "";
                                sendObj.front = lot.front;
                                sendObj.left = lot.left;
                                sendObj.right = lot.right;
                                sendObj.type = "lot";
                                sendObj.deactivateArchitecturalRules = lot.deactivateArchitecturalRules;
                                sendObj.instance = this.instance.id;
                                let siteplan =
                                    this.$store.state.siteplan.appData.id;
                                sendObj.siteplan = lot.siteplan;
                                if (
                                    sendObj.siteplan.id &&
                                    sendObj.siteplan.id != ""
                                )
                                    sendObj.siteplan = sendObj.siteplan.id;
                                sendObj.shapeId = lot.shapeId;
                                sendObj.shape = lot.shape;

                                sendObj = {
                                    inherit: this.parentLot.inherit,
                                    instance: this.instance.id,
                                    shapeId: lot.shapeId,
                                    shape: lot.shape,
                                    siteplan: lot.siteplan,
                                    type: "lot",
                                };

                                this.loading = true;
                                this.$api
                                    .post(
                                        `/lots/${this.instance.id}/${siteplan}`,
                                        sendObj
                                    )
                                    .then(({ data }) => {
                                        this.loading = false;
                                        if (typeof data.inherit == "object") {
                                            data.inherit = data.inherit.id;
                                        }
                                        this.$store.commit(
                                            "OPEN_LEFTNAV",
                                            parseInt(data.shapeId)
                                        );
                                        this.onClose({ type: "add", data });
                                    }).catch(err => {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											this.$message.error(this.$err(err))
										}
									}).finally(() => {
										this.loading = false;
									})
                            }
                        });
                    }
                });
            }
        },
        updateLot() {
            if (
				this.parentLot.inherit == 'none' &&
                this.lot.status != "available" &&
                this.lot.status != "inventory" &&
                this.lot.status != "notreleased"
            ) {
                this.$message.error(
                    "This lot cannot be updated because it is sold or pending"
                );
            } else {
                if (
                    !this.hasChildren ||
                    this.parentLot.inherit == "" ||
                    this.parentLot.inherit == "none"
                ) {
                    if (
                        this.newGrading.name.trim() &&
                        !this.newGrading.value.trim()
                    )
                        return this.$message.error(
                            "Please enter the new grading value"
                        );
                    if (
                        this.newGrading.value.trim() &&
                        !this.newGrading.name.trim()
                    )
                        return this.$message.error(
                            "Please enter the new grading name"
                        );

                    if (
                        this.newPremium.name.trim() &&
                        !this.newPremium.value.trim()
                    )
                        return this.$message.error(
                            "Please enter the new premium value"
                        );
                    if (
                        this.newPremium.value.trim() &&
                        !this.newPremium.name.trim()
                    )
                        return this.$message.error(
                            "Please enter the new premium name"
                        );

                    for (var i = 0; i < this.lot.uniqueGrades.length; i++) {
                        if (
                            !this.lot.uniqueGrades[i].name.trim() &&
                            this.lot.uniqueGrades[i].value == ""
                        ) {
                            this.lot.uniqueGrades.splice(i, 1);
                        }
                    }
                    this.$refs.lot.validate(async (valid) => {
                        if (valid) {
                            this.loading = true;
                            if (this.lot.right == "none") this.lot.right = "";
                            if (this.lot.left == "none") this.lot.left = "";
                            if (this.lot.front == "none") this.lot.front = "";
							if (this.lot.size == "") this.lot.size = 0
                            if (this.lot.size && this.lot.size != "")
                                this.lot.size = Number(this.lot.size);
                            let emptyPremium = [];

                            this.lot.premiums.forEach((x) => {
                                if (
                                    !x.name.trim() &&
                                    (x.value == null || x.value == "")
                                ) {
                                    emptyPremium.push(x);
                                }
                            });

                            emptyPremium.forEach((mt) => {
                                let index = this.lot.premiums.findIndex(
                                    (premium) => premium.id == mt.id
                                );
                                this.lot.premiums.splice(index, 1);
                            });

                            if (
                                this.newGrading.name.trim() &&
                                this.newGrading.value.trim()
                            )
                                this.lot.uniqueGrades.push(this.newGrading);
                            if (
                                this.newPremium.name.trim() &&
                                this.newPremium.value.trim()
                            )
                                this.lot.premiums.push(this.newPremium);

                            this.lot.uniqueGrades = this.lot.uniqueGrades.map(
                                (x) => ({
                                    name: x.name,
                                    value: x.value,
                                })
                            );
                            this.lot.premiums = this.lot.premiums.map((x) => ({
                                name: x.name,
                                value: x.value,
                            }));

                            Object.values(this.lotPremiums).forEach((x) => {
                                x.price = parseFloat(x.price);
                            });
                            Object.values(this.lotGradings).forEach((x) => {
                                x.price = parseFloat(x.price);
                            });

							if (!this.lot.other) {
								this.lot.other = {};
							}

							this.lot.other.premiums = this.lotPremiums;
							this.lot.other.gradings = this.lotGradings;
							this.lot.other.customFields = this.lot.other.customFields || {};
                            let sendObj = JSON.parse(JSON.stringify(this.lot));
                            if (this.standingInventory) {
                                sendObj.status = "inventory";
                            } else if (this.lot.status == "notreleased") {
                                sendObj.status = "notreleased";
                            } else {
                                sendObj.status = "available";
                            }
							sendObj.closingDate = sendObj.closingDate && (moment(sendObj.closingDate).format('X') * 1000)
                            let street = sendObj.street && sendObj.street.trim();
                            if (
                                sendObj.siteplan.id &&
                                sendObj.siteplan.id != ""
                            )
                                sendObj.siteplan = sendObj.siteplan.id;
                            sendObj.street = street;
                            sendObj = this.tagsToIds(sendObj);

                            sendObj.premiums.forEach((x) => {
                                delete x.id;
                            });
                            sendObj.uniqueGrades.forEach((x) => {
                                delete x.id;
                            });
                            let relations = ["left", "right", "front"];
                            relations.forEach((rel) => {
                                if (!sendObj[rel]) sendObj[rel] = null;
                            });

							if (this.selectedLot !== 'none') {
								await this.createShape(sendObj.shape, sendObj.siteplan)
							}

                            this.$api
                                .put(
                                    `/lots/${this.instance.id}/${sendObj.siteplan}/${sendObj.id}`,
                                    sendObj
                                )
                                .then(({ data }) => {
                                    this.loading = false;
                                    this.onClose({ type: "edit", data });
									if (this.selectedLot !== 'none') {
										this.$store.commit(
											"OPEN_LEFTNAV",
											parseInt(data.shapeId)
										);
									}
                                }).catch(err => {
									this.loading = false
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								});
                        } else {
                            return false;
                        }
                    });
                } else if (
                    this.hasChildren &&
                    this.parentLot.inherit != "" &&
                    this.parentLot.inherit != "none"
                ) {
                    this.$refs.parentLot.validate((valid) => {
                        if (valid) {
                            this.$refs.lot.validate(async(valid) => {
                                if (valid) {
                                    let lot = JSON.parse(
                                        JSON.stringify(this.lot)
                                    );
                                    let sendObj = {};
                                    if (this.standingInventory) {
                                        sendObj.status = "inventory";
                                    } else {
                                        sendObj.status = "available";
                                    }
                                    sendObj.id = lot.id;
                                    sendObj.inherit = this.parentLot.inherit;
                                    if (lot.front == "none") lot.front = null;
                                    if (lot.left == "none") lot.left = null;
                                    if (lot.right == "none") lot.right = null;
									if (lot.size == "") lot.size = 0
                                    sendObj.front = lot.front;
                                    sendObj.left = lot.left;
                                    sendObj.right = lot.right;
                                    sendObj.type = "lot";
									sendObj.deactivateArchitecturalRules = lot.deactivateArchitecturalRules;
                                    sendObj.instance = this.instance.id;
                                    sendObj.siteplan = lot.siteplan;
                                    if (
                                        sendObj.siteplan.id &&
                                        sendObj.siteplan.id != ""
                                    )
                                        sendObj.siteplan = sendObj.siteplan.id;
                                    sendObj.shapeId = lot.shapeId;
                                    this.loading = true;

									if (this.selectedLot !== 'none') {
										await this.createShape(sendObj.shape, sendObj.siteplan)
									}

                                    this.$api
                                        .put(
                                            `/lots/${this.instance.id}/${sendObj.siteplan}/${sendObj.id}`,
                                            sendObj
                                        )
                                        .then(({ data }) => {
                                            this.loading = false;
                                            this.onClose({
                                                type: "edit",
                                                data,
                                            });
											if (this.selectedLot !== 'none') {
												this.$store.commit(
													"OPEN_LEFTNAV",
													parseInt(data.shapeId)
												);
											}
                                        }).catch(err => {
											this.loading = false;
											if (!err || !err.response || !err.response.status || err.response.status !== 400) {
												this.$message.error(this.$err(err))
											}
										});
                                }
                            });
                        }
                    });
                }
            }
        },
        onClose({ type = "", data = {} }) {
            if (this.dialog.type === "add" && (!type || type === "click")) {
				if(!this.pointInfo) {
					return;
				}
                let { settings, self, point } = this.pointInfo;

                let index = settings.spots.findIndex((x) => x.id === point.id);
                settings.spots.splice(index, 1);
                self.addAction();
                self.redraw();
            }
            if (this.hasChildren) {
                this.$refs.parentLot.resetFields();
            }
            this.$store.dispatch("CLOSE_DRAWER", { type, data });
            $("[data-wcp-editor-toolbar-button-name=select]").click();
            this.$refs.lot.resetFields();
            this.differentTypes = false;
            this.differentFrontages = false;
            if (this.hasChildren) {
                this.$refs.parentLot.resetFields();
            }
            this.$store.commit("CLOSE_DRAWER", data);
        },
        onChange: function (checked) {
            this.lot.wob = checked;
        },

		async createShape(shape, siteplanId) {
			try {
				await this.$api.post(`siteplans/:instance/${siteplanId}/spots`, shape)
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while create polygon shape on siteplan. Please try again.`))
				}
			}
		},
		galleryImg(data) {
			console.log('daataaaa', data)
			if(this.standingInventory && data && data.length) {
				console.log('lot edit', this.lot)
				this.lot.media.mediaImage = data
			}
		},
		closeModal() {
			this.previewModal.visible = false
		},
		previewGallery(item) {
			this.previewModal.visible = true
			this.previewModal.item = item.url
		},
    },
};
</script>
<style scoped>
.print {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
<style>
.cancel-button.ant-btn {
    border-color: #ece9f1 !important;
    background-color: #ece9f1;
    color: #3f3356;
}
</style>
